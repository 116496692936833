var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.news.items.length < 1
        ? [
            _c("NodateTempalte3", {
              staticClass: "mt-10",
              attrs: { text: _vm.$t("global.noData") },
            }),
          ]
        : _vm._l(_vm.news.items, function (item, index) {
            return _c(
              "v-col",
              { key: item._id, staticClass: "py-1", attrs: { cols: "12" } },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "text-decoration-none",
                    attrs: {
                      to: {
                        name: "news-item",
                        params: {
                          id: item._id,
                        },
                        query: {
                          i: index + 1,
                        },
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "news card1 rounded title--text subtitle-2 pa-4",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "news__header d-flex align-center" },
                          [
                            _c(
                              "span",
                              {
                                staticClass: "news__header__title",
                                class: [
                                  _vm.theme === "light"
                                    ? "primary--text"
                                    : "secondary--text",
                                ],
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      item.title.length > 12
                                        ? item.title.slice(0, 10) + "..."
                                        : item.title
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                            _c("v-spacer"),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "news__header__time caption hint--text",
                              },
                              [_vm._v(" " + _vm._s(item.start_datetime) + " ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                ),
              ],
              1
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }