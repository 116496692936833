import NodateTempalte3 from '@/components/base/Nodata-template3'
import { mapGetters } from 'vuex'

export default {
  props: {
    news: {
      required: true,
      type: Object,
    },
  },

  components: {
    NodateTempalte3,
  },

  computed: {
    ...mapGetters(['layout', 'theme']),
  },
}
