var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.news.items, function (item, index) {
      return _c(
        "v-col",
        { key: item._id, staticClass: "py-1", attrs: { cols: "12" } },
        [
          _c(
            "router-link",
            {
              staticClass: "text-decoration-none",
              attrs: {
                to: {
                  name: "news-item",
                  params: {
                    id: item._id,
                  },
                  query: {
                    i: index + 1,
                  },
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "news card1 rounded title--text subtitle-2 pa-4",
                },
                [
                  _c(
                    "div",
                    { staticClass: "news__header d-flex align-center" },
                    [
                      _c("Icon", {
                        staticClass: "secondary--text mr-1",
                        attrs: {
                          data: require("@icon/bell.svg"),
                          width: "16px",
                          height: "16px",
                        },
                      }),
                      _c("span", { staticClass: "news__header__title" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              item.title.length > 12
                                ? item.title.slice(0, 10) + "..."
                                : item.title
                            ) +
                            " "
                        ),
                      ]),
                      _c("v-spacer"),
                      _c(
                        "span",
                        {
                          staticClass: "news__header__time caption hint--text",
                        },
                        [_vm._v(" " + _vm._s(item.start_datetime) + " ")]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }