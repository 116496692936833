<template>
  <div>
    <NodataTemplate3
      v-if="news.items.length === 0"
      class="mt-10"
      :text="$t('global.noData')"
    />

    <template v-else>
      <v-col
        v-for="(item, index) in news.items"
        :key="item._id"
        cols="12"
        class="py-1"
      >
        <div
          class="text-decoration-none"
          @click="goMemberMessageItem({id: item._id, i: index + 1})"
        >
          <div
            class="news rounded title--text subtitle-2 pa-4"
            :class="[item.read === 'N' ? 'inputBg' : 'card1']"
          >
            <div class="news__header d-flex align-center">
              <span
                class="news__header__title"
                :class="[item.read === 'N' ? (theme === 'light' ? 'primary--text' : 'secondary--text') : 'hint--text']"
              >
                {{
                  item.title && item.title.length > 12
                    ? item.title.slice(0, 10) + '...'
                    : item.title
                }}
              </span>

              <v-spacer />

              <span
                class="news__header__time caption"
                :class="[item.read === 'N' ? 'icon--text' : 'hint--text']"
              >
                {{ item.start_datetime }}
              </span>
            </div>
            <!-- <div
              class="news__content caption mt-2 desc"
              :class="[item.read === 'N' ? 'icon--text' : 'hint--text']"
            >
              {{ item.content }}
            </div> -->
          </div>
        </div>
      </v-col>
    </template>
  </div>
</template>

<script>
import inboxMixin from '../mixins/inboxMixin'

export default {
  mixins: [inboxMixin],
}
</script>

<style lang="scss" scoped>
.desc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
</style>
