<template>
  <component
    :is="layoutTemplate"
    :id="id"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { curryTemplate } from '../../../utils/template'
import template1 from './template/itemTemplate1.vue'
import template3 from './template/itemTemplate3.vue'
import template4 from './template/itemTemplate4.vue'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['layout']),

    layoutTemplate() {
      const templateObj = {
        template1,
        template3,
        template4,
      }
      return curryTemplate(this.layout, templateObj)
    },
  },
}
</script>

<style lang="scss" scoped></style>
