<template>
  <div class="pt-56 h-100dvh over-flow-y-scroll">
    <v-container>
      <v-row
        v-if="$route.query.t === 'mn' ? memberNewsItem : newsItem"
        class="px-1"
      >
        <v-col
          cols="12"
          class="news"
        >
          <div class="news__title subtitle-1 font-weight-medium">
            {{ $route.query.t === 'mn' ? memberNewsItem.title : newsItem.title }}
          </div>
          <div class="news__date text-right caption hint--text">
            {{ $route.query.t === 'mn' ? memberNewsItem.start_datetime : newsItem.start_datetime }}
          </div>
        </v-col>

        <!-- content -->
        <v-col
          cols="12"
          class="pt-0"
        >
          <div class="card1 pa-4 rounded caption font-weight-regular fix-img-size">
            <div
              v-if="$route.query.t === 'mn'"
              v-html="memberNewsItem.content"
            />
            <div
              v-else
              v-html="newsItem.content"
            />
            <!-- {{ $route.query.t === 'mn' ? memberNewsItem.content : newsItem.content }} -->
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import itemMixin from '../mixins/itemMixin'

export default {
  mixins: [itemMixin],
}

</script>
<style >
/* bonus>item.vue 和 home>index.vue(燈箱)有寫，重構時需另外拉出 */
.fix-img-size img{
  max-width: 100% !important;
  height: auto !important;
}
</style>
