var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll.self",
          value: _vm.onScroll,
          expression: "onScroll",
          modifiers: { self: true },
        },
      ],
      staticClass: "pt-56 h-100dvh over-flow-y-scroll",
      attrs: { id: "news" },
    },
    [
      _c(
        "v-container",
        [
          _c(
            "v-row",
            { staticClass: "px-1 pb-4" },
            [
              _c(
                "v-col",
                { staticClass: "pt-0" },
                [
                  _c(
                    "v-tabs",
                    {
                      attrs: {
                        "background-color": "bg",
                        color: "primary",
                        grow: "",
                      },
                      model: {
                        value: _vm.tab,
                        callback: function ($$v) {
                          _vm.tab = $$v
                        },
                        expression: "tab",
                      },
                    },
                    _vm._l(_vm.tabText, function (item, index) {
                      return _c(
                        "v-tab",
                        { key: index, staticClass: "subtitle-1" },
                        [_vm._v(" " + _vm._s(item) + " ")]
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "12" } },
                [
                  _c(
                    _vm.newContent.component,
                    { tag: "component", attrs: { news: _vm.newContent.props } },
                    [
                      _c(
                        "v-col",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.scrollBottom.progress,
                              expression: "scrollBottom.progress",
                            },
                          ],
                          staticClass: "text-center pb-0",
                          attrs: { cols: "12" },
                        },
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "", color: "primary" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }