
import { mapActions, mapGetters } from 'vuex'
import { timezone } from '@/utils/day'
import { resStatus } from '@/utils/resUtils'

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    form: {
      timezone: '',
      page: 1,
    },
  }),

  computed: {
    ...mapGetters(['filterNews', 'filterMember']),

    newsItem() {
      return this.filterNews(this.id)
    },
    memberNewsItem() {
      return this.filterMember(this.id)
    },
  },

  created() {
    // 每頁十筆 i => 第幾筆新聞
    this.form.page = Math.ceil(+this.$route.query.i / 10)
    this.form.timezone = timezone()
  },

  mounted() {
    this.set_toolbar_title(this.$t('myCenter.news'))
    this.getNewsData()
  },

  updated() {
    if (!this.newsItem) this.$router.replace({ path: '/user/news' })
  },

  methods: {
    ...mapActions([
      'set_toolbar_title',
      'get_news_api',
      'fetch_news_api_success',
    ]),

    getNewsDataSuccess(data) {
      if (this.form.page !== 1) this.fetch_news_api_success(data)
    },

    async getNewsData() {
      const res = await this.get_news_api(this.form)
      resStatus(res, this.getNewsDataSuccess)
    },
  },
}
