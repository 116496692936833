import { mapActions, mapGetters } from 'vuex'
import Nodata from '@/components/base/Nodata.vue'
import NodataTemplate3 from '@/components/base/Nodata-template3.vue'

export default {
  components: {
    Nodata,
    NodataTemplate3,
  },

  props: {
    news: {
      required: true,
      type: Object,
    },
  },

  computed: {
    ...mapGetters(['theme', 'layout']),
  },

  methods: {
    ...mapActions(['update_member_news_status']),

    goMemberMessageItem({ id, i }) {
      this.update_member_news_status(id)

      this.$router.push({
        name: 'news-item',
        params: {
          id: id,
        },
        query: {
          i: i,
          t: 'mn', // memberMessage
        },
      })
    },
  },
}
